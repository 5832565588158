import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'STAKE-SHAN',
    lpAddresses: {
      97: '',
      56: '0x217ada8ed2ab06c586b9bb372edd6009ee0da88f', // SHAN-BUSD LP
    },
    tokenSymbol: 'SHAN',
    tokenAddresses: {
      97: '',
      56: '0x84CFc0427147026368C2aaC4f502d98aaC47eB48',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'SHAN-BUSD-LP',
    lpAddresses: {
      97: '',
      56: '0x217ada8ed2ab06c586b9bb372edd6009ee0da88f',
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '',
      56: '0x84CFc0427147026368C2aaC4f502d98aaC47eB48',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
   {
    pid: 3,
    risk: 5,
    lpSymbol: 'BUSD-BNB-LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 4,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'STAKE-BUSD',
    lpAddresses: {
      97: '',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56', // STAKE BUSD 
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

]

export default farms
